@font-face {
  font-family: 'Avenir LT Pro';
  src: url('./resources/fonts/AvenirLTPro-Medium.eot');
  src: url('./resources/fonts/AvenirLTPro-Medium.eot?#iefix') format('embedded-opentype'),
      url('./resources/fonts/AvenirLTPro-Medium.woff2') format('woff2'),
      url('./resources/fonts/AvenirLTPro-Medium.woff') format('woff'),
      url('./resources/fonts/AvenirLTPro-Medium.ttf') format('truetype'),
      url('./resources/fonts/AvenirLTPro-Medium.svg#AvenirLTPro-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Avenir LT Pro';
  src: url('./resources/fonts/AvenirLTPro-Light.eot');
  src: url('./resources/fonts/AvenirLTPro-Light.eot?#iefix') format('embedded-opentype'),
      url('./resources/fonts/AvenirLTPro-Light.woff2') format('woff2'),
      url('./resources/fonts/AvenirLTPro-Light.woff') format('woff'),
      url('./resources/fonts/AvenirLTPro-Light.ttf') format('truetype'),
      url('./resources/fonts/AvenirLTPro-Light.svg#AvenirLTPro-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Avenir LT Pro';
  src: url('./resources/fonts/AvenirLTPro-Heavy.eot');
  src: url('./resources/fonts/AvenirLTPro-Heavy.eot?#iefix') format('embedded-opentype'),
      url('./resources/fonts/AvenirLTPro-Heavy.woff2') format('woff2'),
      url('./resources/fonts/AvenirLTPro-Heavy.woff') format('woff'),
      url('./resources/fonts/AvenirLTPro-Heavy.ttf') format('truetype'),
      url('./resources/fonts/AvenirLTPro-Heavy.svg#AvenirLTPro-Heavy') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Avenir LT Pro';
  src: url('./resources/fonts/AvenirLTPro-Book.eot');
  src: url('./resources/fonts/AvenirLTPro-Book.eot?#iefix') format('embedded-opentype'),
      url('./resources/fonts/AvenirLTPro-Book.woff2') format('woff2'),
      url('./resources/fonts/AvenirLTPro-Book.woff') format('woff'),
      url('./resources/fonts/AvenirLTPro-Book.ttf') format('truetype'),
      url('./resources/fonts/AvenirLTPro-Book.svg#AvenirLTPro-Book') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Avenir LT Pro';
  src: url('./resources/fonts/AvenirLTPro-Black.eot');
  src: url('./resources/fonts/AvenirLTPro-Black.eot?#iefix') format('embedded-opentype'),
      url('./resources/fonts/AvenirLTPro-Black.woff2') format('woff2'),
      url('./resources/fonts/AvenirLTPro-Black.woff') format('woff'),
      url('./resources/fonts/AvenirLTPro-Black.ttf') format('truetype'),
      url('./resources/fonts/AvenirLTPro-Black.svg#AvenirLTPro-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

html {
  width: 100% !important;
  scroll-behavior: smooth;
}

body {
  background: #f0fbff !important;
  color: #000000 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow-x: hidden !important;
  font-family: 'Avenir LT Pro' !important;
  font-weight: 500 !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {  
  font-weight: 900 !important;  
}

input, button, select, optgroup, textarea {   
  font-size: revert !important;
  
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
*:focus {
  outline: 0;
}
.slider-inner-wrapper {
  font-weight: 900;
  font-size: 16px;
}
.slider-inner-wrapper span {
  width: 100%;
  float: left;
  font-weight: 400;
  text-align: center;
  margin-bottom: 15px;
}
.searched-data-wrapper {
  width: 80%;
  margin-bottom: 53px;
  color: #000000;  
}

.searched-data-wrapper span {
  color: #000000;  
  font-size: 14px;
}

.searched-data-wrapper .search-date {
  color: #007A7A;
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 14px;
}

.searched-data-wrapper-highlight {
  background: #f8fdff;
}

.dashboard-welcome-wrapper {
  float: left;
  width: 600px;
  padding: 0 83px;
  text-align: center;
  font-size: 18px;
  line-height: 27px;
}
.dashboard-welcome-image {
  width: 580px !important;
  margin: auto;
  overflow: hidden;
  padding: 50px 0 20px 0;
}
.dashboard-welcome-image img {
  width: 100%;
}
.dashboard-welcome-image span {
  display: block;
  text-align: center;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 26px;
}
.customModal{
  border-radius: 8px;
  padding: 30px !important;  
}
.customModal .title,
.customModal h2{
  font-size: 24px !important;
  margin: 0 0 7px 0 !important;
}
.Toastify__progress-bar {
    left: 0 !important;
    transform-origin: right !important;
}
.guided-tour{
  min-width: 600px !important;   
}
.guided-tour .introjs-tooltiptext{
  font-size: 14px;  
  padding: 0 20px 20px;  
  line-height: 19px;
}
.guided-tour .introjs-tooltiptext img{
  margin-bottom: 15px;
  max-width: 400px;
}
.guided-tour .introjs-tooltiptext,
.guided-tour .introjs-tooltiptext div,
.guided-tour .introjs-tooltip-title,
.guided-tour .tour-btn{
  font-family: 'Avenir LT Pro' !important;
}
.guided-tour .introjs-tooltiptext div{
  text-align: center;
}
.guided-tour .introjs-tooltiptext div > div{
  text-align: left;
}
.guided-tour .introjs-tooltiptext a{
  color: #E84F6B;
}
.guided-tour .introjs-tooltip-title{
  padding-bottom: 12px; 
}
.guided-tour .tour-btn{
  font-size: 13px;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}
.guided-tour .introjs-tooltiptext strong{  
  padding-top: 15px;  
  display: inline-block;
  font-weight: 900;
  margin-bottom: 8px;
}
.guided-tour .introjs-skipbutton{
  padding: 0;
}
.guided-tour .tour-thankyou{  
  width: 100%;  
}
.tour-thankyou img{
  max-width: 100% !important;
  margin-bottom: 30px !important;
}
.tour-highlight{
  box-shadow: rgb(33 33 33 / 80%) 0px 0px 1px 0px,
  rgb(33 33 33 / 50%) 0px 0px 0px 5000px !important;
}

.comments-box{
  /* top: -335.613px !important; */
  bottom: 0;
  top: auto !important;
}
.comments-box .introjs-arrow.left-bottom,
.comments-box .introjs-arrow.left{
  bottom: 10px !important;  
  top: auto !important;
}

.dashboard-box{
  margin-top: 0 !important;
  transform: translateY(-50%);
}